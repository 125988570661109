@import './varibles';

.footer {
    background-color: $footerColor ;

    .container {
        .footer-menus {
            padding: 24px 0 27px 0;
            border-bottom: 1px solid $footerborder;
            .footer-menu{
                li{
                    a{
                        &:hover{
                            color: $topbarbtn !important;
                            text-decoration: underline !important;
                        }
                    }
                }
            }
            ul {

                .footer-icone {
                    background-color: $footericonbg;
                    // opacity: 0.050;
                    border-radius: 6px;

                    a {
                        text-decoration: none;
                        opacity: 0.69;
                        &:hover{
                            color: $topbarbtn !important;
                            text-decoration: underline !important;
                            opacity: 1 !important;
                        }
                        // .follow-icon {
                        //     color: $footericoncolor !important;
                        //     opacity: 0.69 !important;
                        // }
                    }
                }

            }
        }

        .copyright {
            color: $whiteColor;
            opacity: 0.69;
            padding: 25px 0;
        }
    }
}
@media only screen and (max-width: 512px){
    .footer-menus{
        gap: 10px;
    }
    .footer-menu {
        flex-direction: column;
        text-align: center;
    }
}
@media only screen and (max-width: 375px){
    .footer-menus{
        ul{
            gap: 15px !important;
        }
    }
}