@import './varibles';

.sponcer_section {
    padding-bottom: 130px;
    .container {
        max-width: 1210px !important;
        
        .sponcer_slider {
            background: rgba(130, 190, 225, 0.06);
            border-radius: 10px;
            padding: 75px 20px;
        }
    }
}

.sponcer_section button.slick-arrow.slick-prev {
    background-image: url('../img/slideer_arrow 3.svg') !important;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
    top: 50%;
    left: 20px;
    border-radius: 10px;
    z-index: 1;
    &:hover{
        box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.25);
    }
}

.sponcer_section button.slick-arrow.slick-next {
    background-image: url('../img/slideer_arrow 4.svg') !important;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
    top: 50%;
    right: 17px;
    border-radius: 10px;
    &:hover{
        box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.25);
    }
}

@media only screen and (max-width: 768px){
    .sponcer_section{
        padding: 50px 0px;
    }
}