@import './varibles';

.aboutas-section {
    .container {
        max-width: 1440px !important;

        .about-img {
            img {
                border-radius: 15px;
            }
        }

        .sectiion-title {
            position: relative;
            padding-left: 25px;
            margin-bottom: 34px;
            font-size: 28px;
        }

        .aboutas-details {
            p {
                font-size: 20px;
                color: $logintextcolor;
            }
        }
    }
}

.sectiion-title::after {
    content: url('../img/titledesigen.svg');
    position: absolute;
    left: 0px;
    top: -15px;
}

.category-section {
    padding-top: 50px;

    .container {
        max-width: 1440px !important;

        .categores {
            .category-section-title {
                h3 {
                    font-size: 48px !important;
                }

                .category-title-tagline {
                    font-size: 18px;
                    color: $logintextcolor;
                    max-width: 725px;
                    margin: 0 auto;
                }
            }

            .category-list {
                .category {
                    .category-img {
                        background-color: $categorycolor;
                        padding: 30px;
                        border-radius: 15px;

                    }
                }
            }
        }
    }
}
.costomer-section{
    background-color: $primaryColor;
   .container{
    max-width: 1440px !important;
    .costomers{
        .costomer{
            .people-img{
                img{
                    width: 120px !important;
                    border: 5px solid $topbarbtn;
                }
            }
            .people-say{
                max-width: 650px;
                margin: 0 auto;
            }
        }
    }
} 
}
.slick-dots li button:before{
    color: $topbarbtn !important;
    font-size: 20px !important;
    
}
.sponsor-section{
    .container{
        max-width: 1220px !important;

    }
}