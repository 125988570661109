@import './varibles';

.venue {
    // padding-top: 10px;
    padding-bottom: 70px;
    position: relative;

    .container {
        .section-title {
            font-size: 30px;
            font-weight: 700;
            line-height: normal;
        }
        .slider-menu{
            display: flex;
        .filter {
            position: relative;
            .filter-tabs {
                z-index: 1;
                background-color: $datebg;
                border-radius: 5px;
            }
        }
    }

        .cards {
            padding-top: 65px;

            .smallcard {
                .catigare {
                    margin: 0px 5px 0px 5px !important;
                    border: 1px solid $primaryColor !important;
                    width: auto !important;
                    opacity: 0.5;

                    img {
                        width: 35px;
                        height: 35px;
                        // opacity: 0.50 !important;
                    }

                    .catigare-name {
                        font-size: 16px;
                        color: $primaryColor;
                        font-weight: 500;
                        line-height: normal;
                        // opacity: 0.50 !important;
                    }
                }
            }

        }

        .venue-card {
            padding-top: 55px;
            
            .venue-post {
                margin-bottom: 40px;
                
                .venue-post-details {
                    cursor: pointer;
                    border-radius: 15px;
                    box-shadow: 0px 5px 40px 0px rgba(39, 63, 151, 0.10);
   
                    .venue-img {
                        img {
                            border-radius: 15px 15px 0 0;
                        }

                        .venue-img-tag {
                            top: 20px;
                            left: 20px;
                            // background-color: $topbarbtn;
                            border-radius: 5px;
                        }
                    }

                    .venue-information {
                        padding: 30px;

                        .location {
                            color: $inputdatecolor;
                            font-size: 16px;
                            font-weight: 500;

                            
                        }

                        .post-description {
                            padding-top: 14px;
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 30px;

                            a {
                                color: $posttextcolor !important;
                            }
                        }
                    }
                }
            }

            .eventbtn {
                padding: 15px 42px;
                border-radius: 5px;
                border: 1px solid $lightbgcolor;
                color: $lightbgcolor;
                font-weight: 500;
                font-size: 16px;
                line-height: normal;
                background-color: $whiteColor;
                z-index: 1;

                &:hover {
                    background-color: $lightbgcolor;
                    color: $whiteColor;
                }
            }
        }
    }
}
.post-location-icon {
    color: $lightbgcolor !important;
    width: 16px;
    height: 21px;
}
.venue::after{
    content: url(../img/event-background.png);
    position: absolute;
    top: 0;
    left: 35%;
    opacity: 0.25;
}
.filter::after{
    content: url(../img/event-background1.png);
    position: absolute;
    top: 0;
    right: -25px;
}
a.catigare:hover,
a.active {
    opacity: 1 !important;
}

.filter-tabs li.active {
    background-color: $primaryColor;
    color: $whiteColor !important;
    border-radius: 5px;
    cursor: pointer;
}

.filter-tabs li:hover {
    background-color: $primaryColor;
    color: $whiteColor;
    border-radius: 5px;
    cursor: pointer;
}

input[type="date"] {
    background-color: $datebg;
    border: none;
    padding: 16px 61px 16px 16px;
    border-radius: 5px;
    color: $inputdatecolor;
    flex-direction: row-reverse;
    font-size: 16px;
}

::-webkit-calendar-picker-indicator {
    padding-right: 10px;
    cursor: pointer;
    border-radius: 3px;
    color: $inputdatecolor;
}

.venue-banner{
    padding-top: 60px;
    // padding-bottom: 70px;
    
    .venue-banner_slide {
        border-radius: 25px;
        .venue-banner_details{
            border-radius: 25px;
            position: relative !important ;
            background: linear-gradient(transparent 40%, rgba(0, 0, 0, 0.85));
            width: 100%;
            height: 100%;
            z-index: 999;
            .venue-banner_details-img {
                border-radius: 25px;
                width: 100%;
                max-height: 500px;
                object-fit: cover;
                mix-blend-mode: overlay;
                
                
            }
            .venue-bannre-location{
                bottom: 0;
                left: 3%;
                .venue-banner_title {
                    padding: 9px 0;
                    font-size: 34px;
                    font-weight: 900;
                    line-height: normal;
                    position: relative;
                }
    
                .venue-banner_text {
                    // width: 701px;
                    // height: 80px;
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 29px;
                    margin-bottom: 20px;
                }
            }
            

        }
    }

}

.btn-creator {
    text-decoration: none !important;
    background-color: $footerColor;
    padding: 12px 32px;
    font-size: 16px;
    font-weight: 500;
    color: $whiteColor;
    border-radius: 5px;
    margin-left: 10px;
    position: absolute;
    top: 23px;
    left:43%;
    border: 2px solid $borderColor;


    &:hover {
        background-color: $whiteColor;
        color: $footerColor;
        border: 2px solid $borderColor;
    }
}

@media only screen and (max-width: 1024px) {
    .slider-menu {
        flex-direction: column !important;
        gap: 20px;
    }
}

@media only screen and (max-width: 562px) {
    .venue {
        .venue-slider-navbar {
            .slider-manu {
                .filter {
                    li {
                        padding: 5px 5px !important;
                    }
                }
            }
        }
    }
    .filter {
        flex-direction: column;
        gap: 20px;
        .filter-tabs{
            flex-direction: column;
            gap: 5px;
            // width: ;
        }
    }
}