/* Sign.scss */
@import './varibles';
.sign-form {
  max-width: 500px;
  margin: 0 auto;
    margin-top: 0px;
    margin-bottom: 0px;
  padding: 42px 20px;
  border-radius: 8px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.sign-form form {
  display: flex;
  flex-direction: column;
}

.sign-email,
.sign-pass {
  margin-bottom: 15px;
}

.sign-email label,
.sign-pass label {
  font-weight: bold;
  margin-bottom: 5px;
}

.sign-email input,
.sign-pass input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.sign-btn {
  background-color: $primaryColor;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.sign-btn:hover {
  background-color: $topbarColor !important;
}
/* Sign.scss */
.sign-left{
  background-color: #F4F9FC;
  // color: $whiteColor !important;
}
.login-left{
  min-height: 624px;
  height: 100%;
  
}
.sign-email,
.sign-pass {
  margin-bottom: 15px;
}

.sign-email input,
.sign-pass input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.datepicker{
  width: 100% !important;
}
/* Add the hover animation for input fields */
.sign-email:hover input,
.sign-pass:hover input {
  border-color: $primaryColor; /* Blue border color on hover */
}

.sign-form h4 {
  /* Apply styles to the "Sign In" text */
  transition: color 0.3s ease-in-out;

}
.login-logo{
  padding-bottom: 30px;
}
.login-title{
  padding-bottom: 30px;
}
.sign-form:hover h4 {
  color: primaryColor; /* Change text color to light blue on hover */
}
input.chekbox {
  width: 15px;
}
.remember-forgot span a{
  color: $logintextcolor !important;
}
.singn-up-width span a {
  color: $topbarColor!important;
}
.login-with-btn a:hover{
    background-color: $primaryColor;
}
.login-with-btn{
  cursor: pointer;
}
.sign-left, .sign-right{
  width: 50%;
}
.forgot-pass{
  min-height: 624px;
  height: 100%;
  .sign-form{
    margin-top: 160px !important;
  }
}

.SignIn-Social{
  cursor: pointer;
}

.remember-pass{
  cursor: pointer;
}
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.error-message {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.2rem;
}

@media only screen and (max-width: 768px) {
  .sign{
    flex-direction: column;
  }
  .sign-left, .sign-right{
    width: 100% !important;
  }
  .login-left,.forgot-pass{
    min-height: auto !important;
  }
  .forgot-pass .sign-form{
    margin-top: 0px !important;
  }
}
