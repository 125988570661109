@import './varibles';

.featured-venues{
    padding-bottom: 120px;
    .container{
        .section-title{
            padding-bottom: 66px;
            h2{
                color: $posttextcolor;
                font-size: 30px;
                font-weight: 700;
                line-height: normal;
            }
        }
        .venues-cards{
            .venues-menu{
                ul{
                    padding: 30px;
                    border-radius: 15px;
                    box-shadow: 0px 5px 40px 0px rgba(39, 63, 151, 0.08);
                    li{
                        color: $disebaltext;
                        font-size: 17px;
                        font-weight: 500;
                        line-height: normal;
                        &:hover {
                            color: $lightbgcolor;
                            img{
                                opacity: 1;
                            }
                        }
                        .venue-img{
                            height: 55px;
                            width: 90px;
                            opacity: 0.49;
                        }

                    }
                }
            }
            .venue-date-time {
                background-color: $whiteColor !important;
                padding: 17px 0px 17px 19px;
                border: 1px solid $venuedateborder;
                border-radius: 15px;
                margin: 10px 0px;
                cursor:  pointer !important;
                .venue-date{
                    background-color: $venuedatebg;
                    padding: 12px 28px !important;
                    border-radius: 10px;
                    color: $primaryColor;
                    span{
                        font-size: 17px;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 1.7px;
                        h2{
                            font-size: 34px;
                            font-weight: 700;
                            line-height: normal;
                        }
                    }
                }
                .time-description{
                    padding-left: 25px;
                    .venue-time {
                        color: $inputdatecolor;
                        font-weight: 500;
                    }
                    .venue-details{
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 30px;
                        color: $posttextcolor;
                    }
                }
            }
            
        }
    }
}
.venue-p{
    
    
    margin-top: 120px !important;
}

.venue-date-time:hover{
    box-shadow: 0px 5px 40px 0px rgba(39, 63, 151, 0.08);
}
.features-tag{
    cursor: pointer !important;
}
@media only screen and (max-width: 768px){
    .featured-venues{
        padding: 0px 0px !important;
    }
}
@media only screen and (max-width: 575px){
    .select-date{
        flex-direction: column;
        gap: 10px;
        margin-top: 30px;
    }
    .filter{
        flex-direction: column;
        gap: 10px;
    }
}
.active{
    color: $lightbgcolor !important;
    img{
        opacity: 1  !important;
    }
}