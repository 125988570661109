@import './varibles';

.topbar {
    justify-content: space-between;
    padding: 13px 60px;
    background-color: $topbarColor;
    color: $navbartextColor;

    .date {
        font-size: 15px;
        font-weight: 400;

        svg.svg-inline--fa.fa-calendar-days {
            padding-right: 12px;
        }
    }

    .search {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            padding-left: 40px;
            border-left: 1px solid $borderColor;
        }

        svg.svg-inline--fa.fa-magnifying-glass {
            padding-right: 17px;
        }
    }
}

.navbar {
    background-color: $primaryColor;
    padding: 20px 60px !important;
    ul.navbar-nav {
        li.nav-item{
            a.nav-link{
                color: $whiteColor;
                margin: 0 10px;
                font-size: 16px;
                font-weight: 500;
                line-height: normal;
                &:hover{
                    color: $topbarbtn !important;
                    text-decoration: underline !important;
                }
            }
        }
    }
    .nav-btn {
        text-decoration: none;
        background-color: $topbarbtn;
        padding: 12px 32px;
        font-size: 16px;
        font-weight: 500;
        color: $whiteColor;
        border-radius: 5px;
        
        &:hover {
            background-color: $footerColor;
        }
    }
    .nav-btn2 {
        text-decoration: none;
        background-color: $footerColor;
        padding: 12px 32px;
        font-size: 16px;
        font-weight: 500;
        color: $whiteColor;
        border-radius: 5px;
        margin-left: 10px;

        &:hover {
            background-color: $whiteColor;
            color: $footerColor;
        }
    }
}
.login-creater div{
    margin-bottom: 10px;
}
.navbar-nav .nav-link.active{
    color: $topbarbtn !important;
    text-decoration: underline !important;
}
.search-input{
    background-color: $primaryColor;
    padding: 5px  10px ;
    border-radius: 10px;
    border: 3px solid #ffffff20;
}
.search-input input{
    background-color: transparent !important;
    border: none !important;
    color: $whiteColor;
}
::placeholder{
    color: $whiteColor;
}
.profile-icon{
    background-color: $topbarbtn !important;
}
.dropdown-item:hover {
    background-color: $topbarColor !important;
    color: $whiteColor !important;
    .dropdown-icon{
        color: $whiteColor !important;
        transition: all 0.5s ease-in-out;
    }
}
.dropdown-item.active, .dropdown-item:active {
    background-color: $topbarColor !important;
    color: $whiteColor !important;
    .dropdown-icon{
        color: $whiteColor !important;
    }
}
.text-white{
    cursor: pointer;
}
@media only screen and (max-width: 797px){
    .nav-btn,
    .nav-btn2{
        padding: 12px 18px !important;
    }
}
@media only screen and (max-width: 767px){
    .nav-btn,
    .nav-btn2{
        margin-left: 0px !important;
    }
    a.navbar-brand {
        width: 100px;
    }
    .navbar-toggler{
        border: none !important;
    }
    .navbar-toggler:focus{
        box-shadow: none !important;
    } 
}
@media only screen and (max-width: 620px){
    .date{
        margin-bottom: 20px;
        margin-left: 30px;
    }
    .topbar{
        flex-direction: column;
    } 
}
@media only screen and (max-width: 490px){
    .topbar .search p{
        padding-left: 10px;
    }
    .search-input{
        margin-left: 30px;
    }
}
@media only screen and (max-width: 440px) {
    .navbar{
        padding: 10px 10px 20px 10px !important;
    }
    .topbar{
        padding: 13px 15px;
    }
}
