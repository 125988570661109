@import './varibles';

.contact-us-section{
    padding: 60px 0px  !important;
}
.contactus-title{
    position: relative;
    padding-left: 25px;
    margin-bottom: 24px;
    font-size: 32px !important;
}
.location{
    margin: 0 auto !important;
}
.contactus-title::after {
    content: url('../img/titledesigen.svg');
    position: absolute;
    left: 0px;
    top: -20px;
}
.location-map{
    line-height: 0 !important;
    max-width: 1780px !important;
    width: 100% !important;
    margin: 0 auto !important;
}
.contact-info{
    max-width: 1780px !important;
    margin: 0 auto !important;
    // padding-bottom: 28px !important;
}
.address{
    background-image: url(../img/contact-location.png);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-color: $contactdetailsbg;
    padding: 45px !important;
}
.phone{
    background-image: url(../img/contact-phone.png);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-color: $contactdetailsbgdark;
    padding: 45px !important;

}
.email{
    background-image: url(../img/contact-email.png);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-color: $contactdetailsbg;
    padding: 45px !important;

}
.stay-connected{
    background-image: url(../img/contact-follow.png);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-color: $contactdetailsbgdark;   
    padding: 45px !important;

}



.contact-form {
  max-width: 500px !important;
  margin: 0 auto;
  padding: 20px;
  // border: 1px solid #ccc;
  border-radius: 8px;
  // background-color: #fff;
  margin-top: 30px;
  margin-bottom: 30px;
  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-email,
.contact-pass {
  margin-bottom: 15px;
}

.contact-email label,
.contact-pass label {
  font-weight: bold;
  margin-bottom: 5px;
}

.contact-email input,
.contact-pass input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.contact-btn {
  background-color: $primaryColor;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.contact-btn:hover {
  background-color: $topbarColor !important;
}
/* Sign.scss */
.contact-left{
  background-color: #F4F9FC;
  // color: $whiteColor !important;
}
.contact-left{
  min-height: 624px;
  height: 100%;
  
}
.contact-email,
.contact-pass {
  margin-bottom: 15px;
}

.contact-email input,
.contact-pass input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

/* Add the hover animation for input fields */
.contact-email:hover input,
.contact-pass:hover input {
  border-color: $primaryColor; /* Blue border color on hover */
}

.contact-form h4 {
  /* Apply styles to the "Sign In" text */
  transition: color 0.3s ease-in-out;

}
.login-logo{
  padding-bottom: 30px;
}
.login-title{
  padding-bottom: 30px;
}
.contact-form:hover h4 {
  color: primaryColor; /* Change text color to light blue on hover */
}
input.chekbox {
  width: 15px;
}

.contact-us-img{
  width: 55%;
  height: auto;
}

.error-message {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.2rem;
}
