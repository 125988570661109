@import './varibles';

.event {
    padding-top: 10px;
    padding-bottom: 70px;
    position: relative;

    .container {
        .section-title {
            font-size: 30px;
            font-weight: 700;
            line-height: normal;

        }


        .slider-menu{
            display: flex;
        .filter {
            position: relative;
            .filter-tabs {
                z-index: 1;
                background-color: $datebg;
                border-radius: 5px;
            }
        }
    }

        .cards {
            margin-top: 65px;
            padding-top: 0px !important ;
            max-height: 114px !important;
            .smallcard {
                .catigare {
                    margin: 0px 5px 0px 5px !important;
                    border: 1px solid $primaryColor !important;
                    width: auto !important;
                    opacity: 0.5;
                    cursor: pointer;
                    img {
                        width: 35px;
                        height: 35px;
                        // opacity: 0.50 !important;
                    }

                    .catigare-name {
                        font-size: 16px;
                        color: $primaryColor;
                        font-weight: 500;
                        line-height: normal;
                        // opacity: 0.50 !important;
                    }
                }
            }

        }

        .events {
            padding-top: 55px;

            .event-post {
                margin-bottom: 40px;

                .event-post-details {
                    cursor: pointer;
                    border-radius: 15px;
                    box-shadow: 0px 5px 40px 0px rgba(39, 63, 151, 0.10);

                    .event-img {
                        img {
                            border-radius: 15px 15px 0 0;
                        }

                        .event-img-tag {
                            top: 20px;
                            left: 20px;
                            background-color: $topbarbtn;
                            border-radius: 5px;
                        }
                    }

                    .event-information {
                        padding: 30px;

                        .location {
                            color: $inputdatecolor;
                            font-size: 16px;
                            font-weight: 500;

                            .post-location-icon {
                                color: $lightbgcolor !important;
                                width: 16px;
                                height: 21px;
                            }
                        }

                        .post-description {
                            padding-top: 14px;
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 30px;

                            a {
                                color: $posttextcolor !important;
                            }
                        }
                    }
                }
            }

            
        }
    }
}
.eventbtn {
    padding: 15px 42px;
    border-radius: 5px;
    border: 1px solid $lightbgcolor;
    color: $lightbgcolor;
    font-weight: 500;
    font-size: 16px;
    line-height: normal;
    background-color: $whiteColor;
    z-index: 1;
    
    &:hover {
        background-color: $lightbgcolor;
        color: $whiteColor;
    }
    cursor: pointer;
}
.event::after{
    content: url(../img/event-background.png);
    position: absolute;
    top: 0;
    left: 35%;
    opacity: 0.25;

}
.filter::after{
    content: url(../img/event-background1.png);
    position: absolute;
    top: 0;
    right: -25px;
}
a.catigare:hover,
a.active {
    opacity: 1 !important;
}

.filter-tabs li.active {
    background-color: $primaryColor;
    color: $whiteColor !important;
    border-radius: 5px;
    cursor: pointer;
}

.filter-tabs li:hover {
    background-color: $primaryColor;
    color: $whiteColor;
    border-radius: 5px;
    cursor: pointer;
}

input[type="date"] {
    background-color: $datebg;
    border: none;
    padding: 16px 61px 16px 16px;
    border-radius: 5px;
    color: $inputdatecolor;
    flex-direction: row-reverse;
    font-size: 16px;
}

::-webkit-calendar-picker-indicator {
    padding-right: 10px;
    cursor: pointer;
    border-radius: 3px;
    color: $inputdatecolor;
}

.slick-next:before,
.slick-prev:before {
    display: none;
}

.event button.slick-arrow.slick-next {
    background-image: url('../img/slideer_arrow 1.svg') !important;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
    right: -15px;
    display: none;
    // border: 1px solid  $datebg;
    border-radius: 5px;

    &:hover {
        // box-shadow: ;
        box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.25);
    }
}

.event button.slick-arrow.slick-prev {
    background-image: url('../img/slideer_arrow 2.svg') !important;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
    left: -15px;
    display: none;
    z-index: 1;
    // border: 1px solid  $datebg;
    border-radius: 5px;

    &:hover {
        // box-shadow: ;
        box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.25);
    }
}
.pop-date{
    top: 10px !important;
    left: 20px;
    background-color: #0000003d;
    padding:  10px 20px;
    border-radius: 10px;
    h4{
        line-height: 0px;
        padding-top: 10px;
        padding-bottom: 8px;
        font-weight: 600;
    }
    p{
        line-height: 0px;
        padding-top: 18px;
        padding-bottom: 10px;
    }
}
.pop-days{
    top: 20px;
    right: 25px;
    background-color: $primaryColor;
    padding: 4px 10px 5px 10px;
    border-radius: 50px;
    
    span{
        font-size: 16px;
        font-weight: 600;
        line-height: auto;

    }
}
.slick-disabled{
    opacity: 0.3 !important;
}
.slick-track {
    transform: translate3d(-3px, 0px, 0px);
}

@media only screen and (max-width: 1024px) {
    .slider-menu {
        flex-direction: column !important;
        gap: 20px;
    }
}

@media only screen and (max-width: 562px) {
    .event {
        .event-slider-navbar {
            .slider-manu {
                .filter {
                    li {
                        padding: 5px 5px !important;
                    }
                }
            }
        }
    }
    .filter {
        flex-direction: column;
        gap: 20px;
        .filter-tabs{
            flex-direction: column;
            gap: 5px;
            // width: ;
        }
    }
}
