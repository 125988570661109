@import './varibles';
.favourite-events{
    .favourite-event{
        border-radius: 15px;
        box-shadow: 0px 5px 40px 0px rgba(39, 63, 151, 0.1);
        .favourite-img{
            img{
                border-radius: 15px 15px 0 0;
                 cursor: pointer;
                
            }
            
        }
        .fav-event-details{
            padding: 30px;
            .post-description {
                padding-top: 14px;
                font-size: 18px;
                font-weight: 500;
                line-height: 30px;
                a{
                color: $posttextcolor !important;
                }
            }
        }
    }

}

.favorite-pop-days{
    top: 30px;
    right: 25px;
    background-color: $primaryColor;
    padding: 4px 10px 5px 10px;
    border-radius: 50px;
    
    span{
        font-size: 16px;
        font-weight: 600;
        line-height: auto;

    }
}