@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* @import '~bootstrap/scss/bootstrap.scss'; */
/* In your CSS file */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import './assets/scss/_varibles.scss';

*{
    padding: 0px;
    margin: 0px;
    scroll-behavior: smooth;
    box-sizing: border-box;
  }
  
  html {
    box-sizing: border-box;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
  /* ==========default css========*/
  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s,
  samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th,
  td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  
  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: inline-block;
    width: 100%;
    margin: 0px !important;
  }
  
  ol,
  ul {
    list-style: none;
  }
  
  blockquote,
  q {
    quotes: none;
  }
  
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }
  
  img {
    max-width: 100%;
  }
  
  :hover {
    transition: all 0.5s ease-in-out;
  }
  
  *:focus {
    outline: none;
  }
  
  body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: 0px auto !important;
    padding: 0px;
    background-color: #fff !important;
    background-image: url("./assets/img/bankgroumd.png");
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
    
  }
  .footer{
    position: absolute;
  }

  .container {
      max-width: 1900px !important;
      width: 100% !important;
      padding: 0 60px !important;
      margin: 0px  auto!important;
      // display: block;
    }
    @media only screen and (max-width: 1440px){
      .container{
        padding: 0 30px !important;
      }
    }
    @media only screen and (max-width: 992px){
      .container{
        padding: 0 15px !important;
      }
    }
    @media screen and (max-width: 768px) {
        body{
          position: relative !important;
          padding: 0px !important;
        }
        .footer{
          position: relative !important;
        }
    }
    @media screen and (max-height: 1000px) {
      body{
        position: relative !important;
        padding: 0px !important;
      }
      .footer{
        position: relative !important;
      }
  }