@import './varibles';

.venues {
    padding-top: 58px;
    padding-bottom: 70px;
    position: relative;
    .container {
        .section-title {
            font-size: 30px;
            font-weight: 700;
            line-height: normal;
        }
        .slider-menu{
            display: flex;
        .filter {
            position: relative;
            .filter-tabs {
                z-index: 1;
                background-color: $datebg;
                border-radius: 5px;
            }
        }
    }

        .cards {
            padding-top: 65px;

        }

        .venues-card {
            padding-top: 55px;

            .venues-post {
                margin-bottom: 40px;

                .venues-post-details {
                    border-radius: 15px;
                    box-shadow: 0px 5px 40px 0px rgba(39, 63, 151, 0.10);
                    cursor: pointer;

                    .venues-img {
                        img {
                            border-radius: 15px 15px 0px 0px;
                            height: 250px;
                            width: 100%;
                        }

                        .venues-img-tag {
                            top: 20px;
                            left: 20px;
                            background-color: $topbarbtn;
                            border-radius: 5px;
                        }
                    }

                    .venues-information {
                        padding: 30px;

                        .location {
                            color: $inputdatecolor;
                            font-size: 16px;
                            font-weight: 500;

                            .post-location-icon {
                                color: $lightbgcolor !important;
                                width: 16px;
                                height: 21px;
                            }
                        }

                        .post-description {
                            padding-top: 14px;
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 30px;

                            a {
                                color: $posttextcolor !important;
                            }
                        }
                    }
                }
            }

            .eventbtn {
                padding: 15px 42px;
                border-radius: 5px;
                border: 1px solid $lightbgcolor;
                color: $lightbgcolor;
                font-weight: 500;
                font-size: 16px;
                line-height: normal;
                background-color: $whiteColor;
                z-index: 1;

                &:hover {
                    background-color: $lightbgcolor;
                    color: $whiteColor;
                }
            }
        }
    }
}
.venues::after{
    content: url(../.././assets/img/event-background.png);
    position: absolute;
    top: 0;
    left: 35%;
    opacity: 0.25;

}
.filter::after{
    content: url(../.././assets/img/event-background1.png);
    position: absolute;
    top: 0;
    right: -25px;
}
a.catigare:hover,
a.active {
    opacity: 1 !important;
}

.filter-tabs li.active {
    background-color: $primaryColor;
    color: $whiteColor !important;
    border-radius: 5px;
    cursor: pointer;
}

.filter-tabs li:hover {
    background-color: $primaryColor;
    color: $whiteColor;
    border-radius: 5px;
    cursor: pointer;
}

input[type="date"] {
    background-color: $datebg;
    border: none;
    padding: 16px 61px 16px 16px;
    border-radius: 5px;
    color: $inputdatecolor;
    flex-direction: row-reverse;
    font-size: 16px;
}

::-webkit-calendar-picker-indicator {
    padding-right: 10px;
    cursor: pointer;
    border-radius: 3px;
    color: $inputdatecolor;
}


@media only screen and (max-width: 1024px) {
    .slider-menu {
        flex-direction: column !important;
        gap: 20px;
    }
}

@media only screen and (max-width: 562px) {
    .venues {
        .venues-slider-navbar {
            .slider-manu {
                .filter {
                    li {
                        padding: 5px 5px !important;
                    }
                }
            }
        }
    }
    .filter {
        flex-direction: column;
        gap: 20px;
        .filter-tabs{
            flex-direction: column;
            gap: 5px;
            // width: ;
        }
    }
}