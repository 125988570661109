@import './varibles';

.eventdetails {
    padding: 30px 0;

    .container {
        max-width: 1440px !important;
        .event-title {
            position: relative;
            padding-bottom: 25px;
            padding-left: 25px;
        }

        .event-information {
            .event-img {
                padding: 30px 0px;
                .event-banner{
                    // height: 382px;
                    height: 100%;
                }
                img {
                    border-radius: 25px;
                    width: 100%;
                    max-height: 500px;
                    object-fit: cover;
                }
            }

            .event-details {
                padding: 20px 0;

                .event-description {
                    line-height: 26px;
                    font-size: 18px;
                    font-weight: 400;
                    color: $inputdatecolor;
                    padding-right: 70px;
                    font-family: Montserrat !important; 
                }

                .more-info-links {
                    background-color: $footerColor;
                    border-radius: 15px;
                    .info-btn{
                        padding: 10px 20px;
                        font-size: 14px;
                        font-weight: 500;
                        color: #fff;
                        border: 1px solid #fff;
                        border-radius: 5px;
                        transition: all 0.3s ease-in-out;
                        margin-bottom: 20px;
                        background-color: $topbarbtn;
                        border-color: $topbarbtn;
                        padding: 12px 32px;
                        &:hover{
                            color: $topbarbtn !important;
                            background-color: transparent !important;
                        }
                    }
                    .links {
                        border-color: rgb(225, 225, 225, 0.2) !important;     
                        .event-info-icon {
                            align-items: center !important;
                            justify-content: center!important;
                            display: flex !important;
                            border-radius: 0;
                            cursor: pointer;
                            
                        }
                    }

                }
            }
        }
    }
}
.pop-days{
    top: 20px;
    right: 25px;
    background-color: $primaryColor;
    padding: 4px 10px 5px 10px;
    border-radius: 50px;
    
    span{
        font-size: 16px;
        font-weight: 600;
        line-height: auto;

    }
}
.like-btn{
    padding: 10px 15px;
    background-color: #00000070;
    border-radius: 5px;
    top: 20px;
    left: 20px;
    cursor: pointer;
    a{
        font-size: 20px;
        .like-icon{
            color: #fff;
        }
    }
    &:active{
        .like-icon{
            color: #E83B46;
        }
    }
}
.like-btn:active a{
    color: #E83B46 !important;
}
.link-details:hover, .link-details:hover a, .link-details:hover .event-info-icon{
    color: $topbarbtn !important; 
}
.event-map {
    line-height: 0;
    max-width: 1320px;
    margin: 0 auto;
    padding-bottom: 30px;
}
.like-icon {
    color: white;
  }
  
  .like-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .liked .like-icon {
    color: red; // Apply the red color to the heart icon when liked
  }
  
  
.event-title::after {
    content: url('../img/titledesigen.svg');
    position: absolute;
    top: -15px;
    left: 0px;
}



@media only screen and (max-width: 991px) {
    .eventdetails {
        padding: 10px 0px !important;
    }
    .event-img{
        padding-top: 0px !important;
    }
    .event-details{
        gap: 10px;
        padding: 10px 0px !important;
    }
}
