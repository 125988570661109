$primaryColor: #007AC1;
$topbarbtn: #FDBB30;
$topbarColor: #005492;
$borderColor: #3376A8;
$navbartextColor: #CCDDE9;
$whiteColor: #ffffff;
$footerColor: #002D62;
$datebg: #ECF3F9;
$inputdatecolor: #868D93;
$lightbgcolor: #82BEE1;
$posttextcolor: #424D56;
$disebaltext : #A7B9C9;
$venuedatebg: #C0DFF0;
$venuedateborder: #D3EDFF;
$footerborder: #194272;
$footericonbg: #0D376A;
$footericoncolor: #9EAFC3;
$logintextcolor: #717B85;
$categorycolor: #d5ebfd;
$contactdetailsbg: #73B6DD;
$contactdetailsbgdark:  #5CAAD7;
$Headerbtn2:#df9f1f;
$Headerbtn:#002058;